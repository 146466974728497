import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { LocalizedPageProps } from '../utils/types';
import { HeroText } from '../components/partials/common/hero';
import {
  FullWidthFlipCards,
  FullWidthtexCards,
} from '../components/partials/common/elements/Slider';

const AboutUsPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('about-what-we-do');
  const { lang, alternateUrls } = pageContext;

  const hero: any = t('hero', {
    returnObjects: true,
  });

  const values: any = t('values', {
    returnObjects: true,
  });

  const story: any = t('story', {
    returnObjects: true,
  });

  console.log(values)

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroText
        title={hero.title}
        subtitle={hero.subtitle}
        text={hero.text}
        ctaUrl={hero.ctaUrl}
        ctaText={hero.ctaText}
        image={hero.image}
      />

      <section className="section brand-section">
        <div className="container ">
          <div className="columns is-centered is-vcentered text-row">
            <div className="column is-8 is-12-mobile has-text-centered  text-col">
              {values.title && (
                <h2 className="title title-medium section-title text-black">
                  {values.title}
                </h2>
              )}
              {values.text && <p className="text-big">{values.text}</p>}
            </div>
          </div>
        </div>
        <div className="columns is-centered is-vcentered image-row">
          <div className="column  has-text-centered">
            <FullWidthFlipCards
              slides={values.slides}
              classes="round_dots brand"
            />
          </div>
        </div>
      </section>

      <section className="section history-section">
        <div className="container ">
          <div className="columns is-centered is-vcentered text-row">
            <div className="column is-8 is-12-mobile has-text-centered no-pad">
              {story.title && (
                <h2 className="title title-medium section-title text-black">
                  {story.title}
                </h2>
              )}
              {story.text && <p className="text-big">{story.text}</p>}
            </div>
          </div>
        </div>
        <div className="columns is-centered is-vcentered image-row">
          <div className="column   has-text-centered">
            <FullWidthtexCards
              slides={story.slides}
              classes="round_dots timeline"
            />
          </div>
        </div>
      </section>

      <p>{t('notFoundMessage')}</p>
    </Layout>
  );
};

export default AboutUsPage;
